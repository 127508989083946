import React, { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

const TimeSlot = ({
  duration,
  setDuration,
  timeBlock,
  setTimeBlock,
  selectedTimeBlock,
  ap,
  setAp,
  time,
  setTime,
}) => {
  const locale = useStorage();

  // Add this useEffect to clear timeBlock selection
  useEffect(() => {
    setTimeBlock(undefined); // Reset selection when component mounts
  }, []); // Empty dependency array means this runs once when component mounts

  const handleTimeChange = (input) => {
    setTime((prev) => {
      return { ...prev, [input.name]: input.value };
    });
  };

  const handleTimeBlur = (input) => {
    setTime((prev) => {
      return {
        ...prev,
        [input.name]:
          input.name === "startTime"
            ? input.value <= 0 || input.value > 12
              ? 12
              : input.value.length === 1
              ? 0 + input.value
              : input.value
            : input.value >= 60
            ? 59
            : input.value.length === 1
            ? 0 + input.value
            : input.value,
      };
    });
  };

  return (
    <div className="h-full flex flex-col justify-between mb-[45px] md:mb-0">
      <div>
        <div>
          <h5 className="my-[12px] text-white text-center text-[17px] font-SansSerif">
            {language.time[locale]}
          </h5>
          <div className="flex justify-center items-center w-full">
            <input
              name="startTime"
              className="text-white text-[24px] md:text-[30px] py-[11px] px-0 sm:px-[22px] text-center rounded-[14px] outline-none bg-[#7676803d] w-full max-w-[80px]"
              value={time.startTime}
              onChange={(e) => handleTimeChange(e.target)}
              onBlur={(e) => handleTimeBlur(e.target)}
              maxLength={2}
            />
            <span className="text-white text-[24px] md:text-[30px] mx-[4px]">
              :
            </span>
            <input
              name="endTime"
              className="text-white text-[24px] md:text-[30px] py-[11px] px-0 sm:px-[22px] text-center rounded-[14px] outline-none bg-[#7676803d] w-full max-w-[80px]"
              value={time.endTime}
              onChange={(e) => handleTimeChange(e.target)}
              onBlur={(e) => handleTimeBlur(e.target)}
              maxLength={2}
            />
            {["AM", "PM"].map((apName, idx) => (
              <div
                key={idx}
                className={`${
                  ap === apName
                    ? "bg-white border-[#fff] text-[#111]"
                    : "border-[#C8C8C8] text-white"
                } border-[1px] py-[11px] px-[12px] sm:px-[22px] ml-[8px] rounded-[14px] cursor-pointer`}
                onClick={() => setAp(apName)}
              >
                <p className="text-[24px] md:text-[30px]">{apName}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="my-[36px]">
          <h5 className="my-[12px] text-white text-center text-[17px] font-SansSerif">
            {language.duration[locale]}
          </h5>
          <div className="flex justify-center items-center w-full gap-[12px]">
            {[2, 3, 4, 5, 6].map((dur, idx) => (
              <button
                key={idx}
                className={`rounded-full border-[1px] w-[60px] h-[60px] text-[16px] ${
                  duration === dur
                    ? "bg-white border-[#fff] text-[#111]"
                    : "border-[#C8C8C8] text-white"
                }`}
                onClick={() => {
                  setDuration(dur);
                }}
              >
                {dur} hr
              </button>
            ))}
          </div>
        </div>
        <div>
          <h5 className="mb-[12px] text-white text-center text-[20px] font-SansSerif">
            {language.time_block[locale]}
          </h5>
          <div className="flex flex-col justify-center items-center w-full pb-[100px] md:pb-0 cursor-pointer">
            {selectedTimeBlock.length > 0 ? (
              <>
                {selectedTimeBlock.map((time, idx) => (
                  <div
                    key={idx}
                    onClick={() => {
                      setTimeBlock(idx);
                    }}
                    className={`${
                      timeBlock === idx ? "bg-white" : "bg-[#323235] text-white"
                    } min-w-[140px] rounded-[8px]  px-[9px] py-[7px] text-center text-[17px] font-semibold mb-2`}
                  >
                    {/* {moment(time.startTime, ["H:mm"]).format("hh:mm")} -{" "}
                {moment(time.endTime, ["H:mm"]).format("hh:mm")} {time.hour} */}
                    {time.startTime} - {time.endTime}
                  </div>
                ))}
              </>
            ) : (
              <p className="text-white text-center text-[17px] font-SansSerif">
                {language.no_timeslots[locale]}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeSlot;
