import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchClient } from "../axios-config"; // Ensure this is inside /src

import moment from "moment"; // For checking expiry date

const ReserveCard = ({
  card,
  isGrid,
  setIsModalOpen,
  setOpenContactForm,
  setSelectedType,
}) => {
  const locale = useStorage() || "en";
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();

  const [memberShipData, setMemberShipData] = useState(null);
  const [membershipLoading, setMembershipLoading] = useState(false);

  useEffect(() => {
    const getMemberShipDetails = async () => {
      try {
        setMembershipLoading(true);
        const formData = new FormData();
        formData.append("action", "membershipDetails");
        const res = await fetchClient(
          getAccessTokenSilently,
          true,
          "",
          formData,
          false
        );
        if (res) setMemberShipData(res.data);
      } catch (err) {
        console.error("Error fetching membership details:", err);
      } finally {
        setMembershipLoading(false);
      }
    };

    if (isAuthenticated) {
      getMemberShipDetails();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const hasValidMembership = () => {
    if (!memberShipData) return false;
    const rolePresent = !!memberShipData.role_id; // "4", "2", etc.
    const notExpired = moment(memberShipData.membership_expiry_date).isSameOrAfter(
      moment(),
      "day"
    );
    return rolePresent && notExpired;
  };

  const handleAddSearchParams = () => {
    window.history.replaceState(null, null, "?go&type=privatepods");
    setIsModalOpen(true);
  };

  const handleLink = () => {
    window.open(card.link);
  };

  return (
    <div
      className={`min-w-[285px] md:h-[293px] bg-primaryBg rounded-[8px] p-[24px] ${
        isGrid ? "w-full" : "w-1/3"
      }`}
    >
      <div className="flex flex-col justify-between h-full">
        <div>
          <img className="w-[48px] mt-[21px]" src={card.image} alt="" />
        </div>
        <div>
          <h4 className="text-white text-[20px] md:text-[27px] mb-[8px]">
            {card.title}
          </h4>
          <p className="text-white text-[12px] md:text-[16px] mb-[24px]">
            {card.desc}
          </p>
          {card.link &&
            !(card.like === "reserve_book" || card.like === "event_tickets") && (
              <>
                {!isAuthenticated ? (
                  <button
                    onClick={loginWithRedirect}
                    className="flex justify-center items-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative"
                  >
                    {language.login[locale]}
                  </button>
                ) : membershipLoading ? (
                  <button
                    disabled
                    className="flex justify-center items-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative opacity-70"
                  >
                    {language.loading?.[locale] || "Loading..."}
                  </button>
                ) : !hasValidMembership() ? (
                  <Link
                    to="/membership"
                    className="flex justify-center items-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative"
                  >
                    {language.upgrade_membership[locale]}
                  </Link>
                ) : (
                  <button
                    onClick={handleLink}
                    className="flex justify-center items-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative"
                  >
                    {language.read_title[locale]}
                  </button>
                )}
              </>
            )}
        </div>
        {(card.like === "reserve_book" || card.like === "event_tickets") ? (
          <Link
            to={card.like === "event_tickets" ? "/happenings" : "/library"}
            className="flex justify-center items-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative"
          >
            {card.like === "event_tickets"
              ? language.book[locale]
              : language.read_title[locale]}
          </Link>
        ) : (
          !card.link && (
            <button
              onClick={() => {
                if (card.pod) {
                  handleAddSearchParams();
                } else {
                  setOpenContactForm(true);
                  setSelectedType(card.type);
                }
              }}
              className="flex justify-center items-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative"
            >
              {language.book[locale]}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default ReserveCard;
